<template>
  <div>
    <h4>TIEMPOS Y SALIDAS</h4>
    <!-- <app-select-ym v-model="time" ref="selTime"></app-select-ym> -->
    <SelectStore v-model="store_id"></SelectStore>

    <!-- <div class="form-group">
      <app-checkbox>1 Semana</app-checkbox>
      <app-checkbox>2 Semana</app-checkbox>
      <app-checkbox>3 Semana</app-checkbox>
      <app-checkbox>4 Semana</app-checkbox>
    </div>-->

    <table class="table table-sm">
      <semana ref="semana" @changeTime="getList()"></semana>
      <tbody>
        <tr v-if="$refs.semana">
          <td v-for="(l, i) in $refs.semana.weekDates" :key="i">
            <!-- {{ l }} -->
            <!-- <div class="p-2 border-bottom" v-for="l1 in times[l.]" :key="l1.product_id + 'b'">
              <span
                class="text-right btn btn-secondary mr-2"
              >{{ l1.quantity }} {{ l1.stock_min ? '/'+l1.stock_min : '' }}</span>

              <span>{{ l1.product_name }}</span>
            </div>-->
          </td>
        </tr>
        <tr v-if="$refs.semana">
          <td v-for="(l, i) in $refs.semana.weekDates" :key="i">
            <!-- <span>{{ l.date }}</span> -->
            <div v-for="(l1, i2) in times[l.date]" :key="i2">
              <span class="text-right btn btn-secondary mr-2 btn-sm"
                >{{ l1.quantity }}
                {{ l1.stock_min ? "/" + l1.stock_min : "" }}</span
              >
              <small>{{ l1.product_name }}</small>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <pre hidden>{{ times }} </pre>
    <!-- <table class="table table-responsive table-bordered">
      <thead>
        <tr>
          <th v-for="(l,index) in times" :key="index">{{moment(index).format("dddd DD")}}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td v-for="(l,index) in times" :key="index + 'a'" class="p-0">
            <div class="p-2 border-bottom" v-for="l1 in l" :key="l1.product_id + 'b'">
              <span
                class="text-right btn btn-secondary mr-2"
              >{{ l1.quantity }} {{ l1.stock_min ? '/'+l1.stock_min : '' }}</span>

              <span>{{ l1.product_name }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>-->
  </div>
</template>

<script>
import { LogisticService } from "../service";
import SelectStore from "../stores/Select";
import { myMoment } from "src/lt/utils/myMoment";
import semana from "./semana";

export default {
  components: {
    SelectStore,
    semana
  },
  data: () => ({
    time: null,
    store_id: null,
    times: []
  }),
  mounted() {
    // this.$refs.selTime.setDefault();
    this.getList();
  },
  watch: {
    store_id() {
      this.getList();
    }
  },
  methods: {
    moment: myMoment,
    getList() {
      LogisticService.getOutputTimes({
        ini_date: this.$refs.semana.ini_time,
        end_time: this.$refs.semana.end_time,
        store_id: this.store_id
      }).then((res) => {
        var times = {};

        res.map((x) => {
          // times[x.date] = times[x.date] ? [...times[x.date], ...x] : [...x];
          times[x.time] = times[x.time] ? [...times[x.time], x] : [x];
        });

        // console.log(times);
        this.times = times;

        // console.log(res);
      });
    }
  }
};
</script>

<style></style>
